import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Component/Home";
import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { GiOrganigram } from "react-icons/gi";
import { HiOutlineBars3 } from "react-icons/hi2";
import { GoLightBulb } from "react-icons/go";
import { LuFileCheck2 } from "react-icons/lu";
import { BsChatDots, BsFileBarGraph } from "react-icons/bs";
import img1 from "../src/img/Ellipse 12.png";
import { IoCloseSharp } from "react-icons/io5";

function App() {
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(
    window.matchMedia("(max-width: 800px)").matches
  );
  const Closeside =()=>{
    if(window.innerWidth < 768){
      // setToggled(!toggled)
    }
  }
  return (
    <div style={{ display: "flex", height: "100%", minHeight: "100vh" }}>
      <Sidebar
        className="m-2"
        collapsed={window.innerWidth > 768}
        collapsedWidth="80px"
        toggled={toggled}
        customBreakPoint="800px"
        onBreakPoint={setBroken}
        id="sid"
      >
        <div className="text-end d-block d-md-none">
        <IoCloseSharp className="me-2 mt-2" size={25} onClick={()=>setToggled(false)} />
        </div>
        <Menu >
          <MenuItem className="my-4" icon={<img src="https://picsum.photos/200" className="widthsetside"/>} > lorem ipsum </MenuItem>
          <MenuItem className="my-4" icon={<BsFileBarGraph  color="white" size={20}/>} > E-commerce</MenuItem>
          <SubMenu className="my-4" label="Charts" icon={<GiOrganigram color="white" size={20}/>}>
            <MenuItem> Pie charts</MenuItem>
            <MenuItem> Line charts</MenuItem>
            <MenuItem> Bar charts</MenuItem>
          </SubMenu>
          <MenuItem className="my-4" icon={<BsChatDots  color="white" size={20}/>} > Examples</MenuItem>
          <MenuItem className="my-4" icon={<GoLightBulb  color="white" size={20}/>} > Calendar</MenuItem>
          <MenuItem className="my-4" icon={<LuFileCheck2  color="white" size={20}/>} > E-commerce</MenuItem>
          <MenuItem className="my-2 mx-2" > <div className="dot1" ></div></MenuItem>
          <MenuItem className="my-2 mx-2" >      <div className="dot2" ></div> </MenuItem>
        </Menu>
   
        <div id="bottomset" className="">
            <img src={img1} className="widthsetside"/>
        </div>
      </Sidebar>
      <main style={{ display: "flex", width: "100%" ,height:"100vh" , overflow:"scroll" }} className="px-4">
        <div className="w-100" onClick={()=>Closeside()}>
          {broken && (
            <HiOutlineBars3 size={25} onClick={() => setToggled(!toggled)}/>
          )}

          <Home />
        </div>
      </main>
    </div>
  );
}

export default App;
