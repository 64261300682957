import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Vector from "../img/Vector.png";

const Cards = () => {
  return (
    <>
      {/* <div className="p-0 m-1">
        <div className="cardset m-0">
          <Row className="align-items: center">
            <div className="col-3 d-flex justify-content-center">
              <div className="vaxtorbg">
                <img src={Vector} className="Vectorimg" />
              </div>
            </div>
            <div className="col-9">
              <h4 className="mb-0">Brainstorm Jam</h4>
              <p className="mb-0">10 AM - 11 AM</p>
            </div>
            <Col>
              <div className="d-flex flex-wrap">
                <div className="tag tag1colour">Tag</div>
                <div className="tag tag2colour">Tag</div>
                <div className="tag tag3colour">Tag</div>
                <div className="tag tag4colour">Tag</div>
                <div className="tag tag5colour">Tag</div>
              </div>
            </Col>
          </Row>
        </div>
      </div> */}
       <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3">
        <div className="cardset m-0">
          <Row className="align-items: center">
            <div className="d-flex ">
                <div className="">
                <div className="vaxtorbg">
                    <img src={Vector} className="Vectorimg" />
                </div>
                </div>
                <div className="mx-3">
                <h4 className="mb-0 Arialfont">Brainstorm Jam</h4>
                <p className="mb-0 latofont">10 AM - 11 AM</p>
                </div>
            </div>

            <Col>
              <div className="d-flex flex-wrap">
                <div className="tag tag1colour">Tag</div>
                <div className="tag tag2colour">Tag</div>
                <div className="tag tag3colour">Tag</div>
                <div className="tag tag4colour">Tag</div>
                <div className="tag tag5colour">Tag</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Cards;
