import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import img1 from "../img/Ellipse 12.png";
import img2 from "../img/Ellipse 13.png";
import img3 from "../img/Ellipse 15.png";
import Cards from "./Cards";
const Home = () => {
  const [cardData, setCardData] = useState(["1", 1, 1, 1, 1,1]);
  const [profile, setProfile] = useState([
    {
      src: img1,
    },
    {
      src: img2,
    },
    {
      src: img3,
    },
    {
      src: img1,
    },
    {
      src: img2,
    },
    {
      src: img3,
    },
  ]);
  return (
    <Container fluid>
      <Row className="p-0">
        <Col className="p-0">
          <div className="headerset">
            <div className="title-section">
              <div className="gradient-text">Overview</div>
              <div className="estimated-time mt-3">Estimated Time ~56 Hrs</div>
            </div>
            <div className="button-container">
              <button className="button button-blue">Overview</button>
              <button className="button button-gray">Sprint Board</button>
              <button className="button button-gray">Day-To-Day</button>
            </div>
          </div>
        </Col>
      </Row>
      <Row >
        <Col className="bg-gray mt-3 px-3 px-md-5">
          <Row className="p-2">
            <Col md={12} className="p-0">
              {" "}
              <h3 className="my-3 Arialfont">Sprint 1</h3>
            </Col>
            <Col md={12} className="p-0">
                <div className="row">
                    {cardData.map((e,index) => {
                    return (
                        <Cards key={index}/>
                    );
                    })}
                </div>
            </Col>
          </Row>
          <Row className="p-2 my-4">
            <Col md={2} className="SprintObjective Arialfont">
              Sprint Objective:{" "}
            </Col>
            <Col md={10} className="SprintObjectivecontent Arialfont">
              The sprint objective is to develop and implement key features that
              will enhance the user experience and functionality of the watch
              buying and selling platform. This includes creating a seamless
              listing creation and approval process for sellers, as well as
              implementing robust search and filter functionalities for buyers. {" "}
            </Col>
            </Row>
          <Row className="my-4 p-2">
            <Col md={2} className="SprintObjective Arialfont">Resources </Col>
            <Col md={10}>
              <div className="resources">
                {profile.map((e,index) => {
                  return <img  src={e.src} alt="Profile" />;
                })}
              </div>
            </Col>
            </Row>
          <Row className="p-2">
            <Col md={2} className="SprintObjective Arialfont"> Duration of Sprint: </Col>
            <Col md={10} className="SprintObjectivecontent Arialfont">22 hours</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
